import Cookies from "js-cookie";

interface CacheVersion {
    version: string;
    timestamp: number;
}

const DEFAULT_VERSION = "1.0.0";

export const CACHE_VERSION =
    process.env.NEXT_PUBLIC_BUILD_ID || DEFAULT_VERSION;

export class CacheManager {
    private static readonly VERSION_COOKIE_NAME = "app_version";

    private static readonly CACHE_VERSION_KEY = "cache_version";

    private static readonly RELOAD_PENDING_KEY = "reload_pending";

    private static readonly PRESERVED_COOKIES = [
        "language",
        "country",
        "CookieConsent"
    ];

    // Add sensitive routes that shouldn't be immediately reloaded
    private static readonly SENSITIVE_ROUTES = [
        "/checkout",
        "/payment",
        "/enrollment/form"
    ];

    static async clearAllCache(): Promise<void> {
        try {
            // Store preserved cookies
            const preservedCookieValues = this.PRESERVED_COOKIES.reduce(
                (acc, cookieName) => {
                    const value = Cookies.get(cookieName);
                    if (value) {
                        acc[cookieName] = value;
                    }
                    return acc;
                },
                {} as Record<string, string>
            );

            // Clear browser cache using Cache API
            if ("caches" in window) {
                const cacheKeys = await caches.keys();
                await Promise.all(cacheKeys.map(key => caches.delete(key)));
            }

            // Clear local storage
            localStorage.clear();

            // Clear session storage
            sessionStorage.clear();

            // Clear all cookies except preserved ones
            const cookies = Cookies.get();
            Object.keys(cookies).forEach(cookie => {
                if (!this.PRESERVED_COOKIES.includes(cookie)) {
                    Cookies.remove(cookie, { path: "/" });
                }
            });

            // Restore preserved cookies
            Object.entries(preservedCookieValues).forEach(([name, value]) => {
                Cookies.set(name, value, {
                    expires: 365, // Set expiration to 1 year
                    path: "/"
                });
            });

            // Set new version
            this.setVersion();

            console.log(
                "Cache and cookies cleared successfully (preserved cookies: language, country, CookieConsent)"
            );
        } catch (error) {
            console.error("Error clearing cache and cookies:", error);
            throw error;
        }
    }

    static setVersion(): void {
        const versionData: CacheVersion = {
            version: CACHE_VERSION,
            timestamp: Date.now()
        };

        // Store version in both localStorage and cookies for redundancy
        localStorage.setItem(
            this.CACHE_VERSION_KEY,
            JSON.stringify(versionData)
        );
        Cookies.set(this.VERSION_COOKIE_NAME, CACHE_VERSION, { expires: 7 });
    }

    static checkVersion(): boolean {
        // If no version is stored yet, set it without forcing a reload
        if (!this.hasStoredVersion()) {
            this.setVersion();
            return true;
        }

        const storedVersionData = localStorage.getItem(this.CACHE_VERSION_KEY);
        const cookieVersion = Cookies.get(this.VERSION_COOKIE_NAME);

        if (!storedVersionData || !cookieVersion) {
            return false;
        }

        try {
            const { version } = JSON.parse(storedVersionData) as CacheVersion;
            return version === CACHE_VERSION && cookieVersion === CACHE_VERSION;
        } catch {
            return false;
        }
    }

    private static hasStoredVersion(): boolean {
        return !!(
            localStorage.getItem(this.CACHE_VERSION_KEY) &&
            Cookies.get(this.VERSION_COOKIE_NAME)
        );
    }

    static async handleVersionMismatch(): Promise<void> {
        // Skip version check if running locally without BUILD_ID
        if (
            process.env.NODE_ENV === "development" &&
            !process.env.NEXT_PUBLIC_BUILD_ID
        ) {
            return;
        }

        if (!this.checkVersion()) {
            // Check if we're on a sensitive route
            const currentPath = window.location.pathname;
            const isSensitivePage = this.SENSITIVE_ROUTES.some(route =>
                currentPath.includes(route)
            );

            // Check for active forms
            const hasActiveForms = document.querySelectorAll("form").length > 0;

            if (isSensitivePage && hasActiveForms) {
                // Set pending reload flag
                localStorage.setItem(this.RELOAD_PENDING_KEY, "true");

                // Show update notification (can be implemented separately)
                console.log(
                    "New version available. Will update after form completion."
                );
                return;
            }

            await this.clearAllCache();
            window.location.reload();
        }
    }

    // Add method to check for pending reloads (call this after form submissions)
    static checkPendingReload(): void {
        const pendingReload = localStorage.getItem(this.RELOAD_PENDING_KEY);
        if (pendingReload === "true") {
            localStorage.removeItem(this.RELOAD_PENDING_KEY);
            this.handleVersionMismatch();
        }
    }
}

// Initialize version check on app load
if (typeof window !== "undefined") {
    CacheManager.handleVersionMismatch().catch(console.error);
}
